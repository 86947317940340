enum CategoryClient {
  VIZEO = 'VIZEO',
  DISTRIBUTEUR = 'DISTRIBUTEUR',
  DISTRIBUTEUR_VVA = 'DISTRIBUTEUR_VVA',
  DIVERS = 'DIVERS',
  INSTALLATEUR = 'INSTALLATEUR',
  REPRESENTANT = 'REPRESENTANT',
  BUREAU_ETUDE = 'BUREAU_ETUDE',
  FOURNISSEUR = 'FOURNISSEUR',
  CLIENT = 'CLIENT',
  SPECIALISTE = 'SPECIALISTE',
}

export default CategoryClient;
